<template>
  <div class="passport-grid v-row">
    <div class="d-flex flex-column justify-space-around v-col-xs-12 v-col-lg-5">
      <div class="mx-auto">
        <ImageReview
          :nid="page?.rels?.nid"
          :content-type="page.typename"
          :download-nick="nick"
          :software-title="soft"
          :alt="alt || 'review'"
        />
      </div>

      <Rating v-if="page.portal" v-model="popularity" class="mx-auto"></Rating>
      <span v-if="popularity >= 3" class="text-reputation font-weight-bold text-body-2 mx-auto mb-2">
        Excellent reputation
      </span>
      <div v-if="welcomeBonus" class="bonusClass mb-1 bg-reviewBonusBg rounded-lg pa-6">
        <v-row>
          <v-col cols="3">
            <v-icon icon="$gift" color="redColor" size="x-large" class="ma-auto text-center pa-0 ma-0"></v-icon>
          </v-col>
          <v-col cols="9" class="font-weight-bold">
            <span class="text-caption text-grey-4">Welcome Bonus</span><br />
            {{ welcomeBonus }}
          </v-col>
        </v-row>
      </div>
      <Button
        :download-nick="nick"
        :software-title="soft"
        :text="page.downloadTitle"
        class="w-100 text-uppercase font-weight-bold"
      />
    </div>
    <div class="v-col-xs-12 v-col-lg-6">
      <div class="pl-2 pb-2">
        <div class="pt-2 pb-3 text-capitalize font-weight-bold">More info</div>
        <span v-if="page.typename === 'BrandReview'">
          <v-divider v-if="currencies" class="pb-2"></v-divider>
          <div v-if="currencies" class="v-row">
            <span class="v-col-4">Currencies</span>
            <span class="v-col-8"><see-more :arr="currencies" /></span>
          </div>
          <v-divider v-if="paymentMethods" class="pb-2"></v-divider>
          <div v-if="paymentMethods" class="v-row">
            <span class="v-col-4">Payment Methods</span>
            <span class="v-col-8"><see-more :arr="paymentMethods" /></span>
          </div>

          <v-divider v-if="offerTournaments" class="pb-2"></v-divider>
          <div v-if="offerTournaments" class="v-row">
            <span class="v-col-4">Offer Tournaments</span>
            <span class="v-col-8">{{ offerTournaments }}</span>
          </div>

          <v-divider v-if="jurisdiction" class="pb-2"></v-divider>
          <div v-if="jurisdiction" class="v-row">
            <span class="v-col-4">Jurisdiction</span>
            <span class="v-col-8"><see-more :arr="jurisdiction" /></span>
          </div>
          <div v-if="supportOptions.length > 0" class="pl-2">
            <div class="mt-2 pt-2 pb-3 text-capitalize font-weight-bold">Support</div>
            <v-divider class="pb-2"></v-divider>
            <div>
              <span v-for="option in supportOptions" :key="option.name" class="px-2">
                <v-icon :icon="option.icon" :color="option.color" size="large"></v-icon>
                {{ option.name }}
              </span>
            </div>
          </div>
        </span>
        <span v-if="page.typename === 'SlotsGameReview'">
          <v-divider v-if="software" class="pb-2"></v-divider>
          <div v-if="software" class="v-row">
            <span class="v-col-4">Software</span>
            <span class="v-col-8">{{ software }}</span>
          </div>
          <v-divider v-if="gameTypes" class="pb-2"></v-divider>
          <div v-if="gameTypes" class="v-row">
            <span class="v-col-4">Types</span>
            <span class="v-col-8"><see-more :arr="gameTypes" /></span>
          </div>
          <v-divider v-if="gameThemes" class="pb-2"></v-divider>
          <div v-if="gameThemes" class="v-row">
            <span class="v-col-4">Themes</span>
            <span class="v-col-8"><see-more :arr="gameThemes" /></span>
          </div>
          <v-divider v-if="paylines" class="pb-2"></v-divider>
          <div v-if="paylines" class="v-row">
            <span class="v-col-4">Paylines</span>
            <span class="v-col-8">{{ paylines }}</span>
          </div>
          <v-divider v-if="maxBet" class="pb-2"></v-divider>
          <div v-if="maxBet" class="v-row">
            <span class="v-col-4">Max Bet</span>
            <span class="v-col-8">{{ maxBet }}</span>
          </div>
          <v-divider v-if="bonusRound" class="pb-2"></v-divider>
          <div v-if="bonusRound" class="v-row">
            <span class="v-col-4">Bonus Round</span>
            <span class="v-col-8"><see-more :arr="bonusRound" /></span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
const page = inject("page");
const rels = unref(page).rels;
const nick = rels?.downloadNick || "";
const soft = rels?.softwareTitle || "";
const software =
  rels?.Software && unref(page).typename === "SlotsGameReview" ? rels?.Software.replace(/ *\([^)]*\) */g, "") : "";

const offerTournaments = rels?.["Offer tournaments"] === "Yes" ? rels?.["Offer tournaments"] : "";
const jurisdiction = rels?.Jurisdiction && rels?.Jurisdiction.length > 0 ? rels.Jurisdiction : "";
const welcomeBonus = _.get(rels, ["Welcome Bonus", "title"], "");
const currencies = rels?.Currencies || "";
const paymentMethods = rels?.["Payment Methods"] || "";
const gameTypes = rels?.Type || "";
const gameThemes = rels?.Themes || "";
const paylines = rels?.Paylines || "";
const bonusRound = rels?.["Bonus round"] && rels?.["Bonus round"].length > 0 ? rels?.["Bonus round"] : "";
const maxBet = rels?.["Max Bet"] || "";
const alt = rels?.Title;
const popularity = rels?.Popularity;
const supportOptions = computed(() => {
  const tmp = [];
  _.mapKeys(_.get(rels, ["Support options"], {}), function (value, key) {
    tmp.push({ name: _.capitalize(key), color: value ? "#26c280" : "#ff3344", icon: value ? "$check" : "$close" });
  });
  return tmp;
});
</script>
